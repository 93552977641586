import { Component, ViewEncapsulation } from '@angular/core';
import { PlanTemplate } from '../models/plan.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent {
  planTemplateEnum: typeof PlanTemplate = PlanTemplate;

}
