import { Component, ViewEncapsulation } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-user-cookies-consent',
  templateUrl: './user-cookies-consent.component.html',
  styleUrls: ['./user-cookies-consent.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class UserCookiesConsentComponent {
  cookieConsentValues: any = null;
  functional: boolean = true;
  analytics: boolean = false;
  advertisement: boolean = false;

  constructor(private analyticsService: AnalyticsService) {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (cookieConsent) {
      try {
        this.cookieConsentValues = JSON.parse(cookieConsent);
        this.functional = this.cookieConsentValues.functional;
        this.analytics = this.cookieConsentValues.analytics;
        this.advertisement = this.cookieConsentValues.advertisement;
        this.setConsent();
        
      } catch (error) {
        localStorage.removeItem("cookieConsent");
        this.cookieConsentValues = null;
      }
    }
  }

  setConsent() {
    this.cookieConsentValues = {functional: this.functional, analytics : this.analytics, advertisement: this.advertisement}
    this.analyticsService.setConsent( this.cookieConsentValues);
  }
 
  close() {
    this.cookieConsentValues = {functional: this.functional, analytics : this.analytics, advertisement: this.advertisement}
    localStorage.setItem("cookieConsent", JSON.stringify(this.cookieConsentValues));
    console.log(this.cookieConsentValues);
  }
}

