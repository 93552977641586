<form  [formGroup]="playerFormGroup" class="player-new flex flex-wrap" >
  <div class="u__form-row">
    <mat-form-field class="u__flex--fullwidth  flex-1" style="min-width: 150px;">
      <mat-label i18n>Název</mat-label>
      <input matInput i18n-placeholder  placeholder="Název" formControlName="name">
      <!--mat-icon matPrefix [color]="f['name'].valid ? 'primary' : 'warn'">{{ f['name'].valid ? 'done' : 'priority_high' }}</mat-icon-->
    </mat-form-field>
  </div>
  <div class="player-new__row u__form-row">
    <mat-form-field >
      <mat-label i18n>Plocha provozovny v m2</mat-label>
      <input type="number" step="1"  matInput i18n-placeholder placeholder="Plocha provozovny" formControlName="area">
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label i18n>Typ provozovny</mat-label>
      <!--mat-icon matPrefix [color]="f['storeType'].valid ? 'primary' : 'warn'">{{ f['storeType'].valid ? 'done' : 'priority_high' }}</mat-icon-->
      <mat-select formControlName="storeType">
        <mat-option class="mt-2 mb-2" *ngFor="let storeType of storeTypes" [value]="storeType.storetypeId" >{{ storeType.title | languageMutation:locale }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="player-new__row  u__form-row">
    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Adresa</mat-label>
      <input matInput i18n-placeholder placeholder="Adresa" formControlName="street">
      <!--mat-icon matPrefix [color]="f['street'].valid ? 'primary' : 'warn'">{{ f['street'].valid ? 'done' : 'priority_high' }}</mat-icon-->
    </mat-form-field>
    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Město</mat-label>
      <input matInput i18n-placeholder placeholder="Město" formControlName="city">
      <!--mat-icon matPrefix [color]="f['city'].valid ? 'primary' : 'warn'">{{ f['city'].valid ? 'done' : 'priority_high' }}</mat-icon-->
    </mat-form-field>
    <mat-form-field class="u__form--150">
      <mat-label i18n>PSČ</mat-label>
      <input   matInput maxlength="5" i18n-placeholder placeholder="PSČ" formControlName="zip">
      <!--mat-icon matPrefix [color]="f['zip'].valid ? 'primary' : 'warn'">{{ f['zip'].valid ? 'done' : 'priority_high' }}</mat-icon-->
    </mat-form-field>
    <mat-form-field class="u__form--300">
      <mat-label i18n>Země</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let countryCode of plan?.countryRestrictions?.allowed" [value]="countryCode" >{{ getCounryTitle(countryCode) }}</mat-option>
        </mat-select>
    </mat-form-field>
  </div>  
</form>