import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../order.service';
import { RequestResponse } from 'src/app/models/request-status';
import { Order, OrderState } from 'src/app/models/orders.model';

@Component({
  selector: 'app-order-payment-state',
  templateUrl: './order-payment-state.component.html',
  styleUrls: ['./order-payment-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderPaymentStateComponent  {  
  orderId: string | null = null;
  order: Order| null = null;
  orderStateEnum: typeof OrderState = OrderState;
  isLoading = false;

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService) {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['orderId']) {
        this.orderId = params['orderId'];
      }
  });
  }
  ngOnInit() {
    if(this.orderId) {
      this.isLoading = true;
      setTimeout(() => {
        this.fetchOrderDetail(this.orderId!);
      },10000)
    }
  }
  fetchOrderDetail(id:string) {
    this.orderService.fetchOrderDetail(id).subscribe({
      next: (data: RequestResponse) => {
        if (data)
          this.order = data.data.order
          this.isLoading = false;
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    })
  }
}
